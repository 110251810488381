import axios from 'axios';

export const listProducts = async () => {
    return await axios.get("/firmwareHub/products").then((response: any) => response.data).catch((err: any) => err);
}

export const listReleases = async (payload: {product: any}) => {
    return await axios.post("/firmwareHub/releases", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const downloadRelease = async (releaseId: string) => {
    return await axios.get(`/firmwareHub/releases/${releaseId}`).then((response: any) => response.data).catch((err: any) => err);
}
